import React from 'react'

type FaqItemProps = {
    question: string,
    message: string,
    isExpanded: boolean,
    onClick: () => void,
}

export default function FaqItem(props: FaqItemProps) {
  return (
    <div className={`relative mb-3 rounded-xl border ${props.isExpanded ? 'border-primary' : 'border-lightgrey100'} border-solid`}>
        <h6 className="">
            <button 
                className="relative flex items-center w-full p-4 font-semibold text-left transition-all ease-in border-b border-solid cursor-pointer border-slate-100 text-slate-700 rounded-t-1 group text-dark-500"
                data-collapse-target="animated-collapse-1"
                onClick={() => props.onClick()}
            >
                <span className={props.isExpanded ? 'text-primary' : 'text-grey'}>{props.question}</span>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="text-primary size-6 absolute right-2 transition-transform group-open:rotate-180"> <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" /> </svg>
            </button>
        </h6>
        <div
            data-collapse="animated-collapse-1"
            className={`${props.isExpanded ? '' : 'h-0'} overflow-hidden transition-all duration-300 ease-in-out`}
        >
            <div className="p-4 text-sm leading-normal text-blue-gray-500/80">
                {props.message}
            </div>
        </div>
    </div>
  )
}
