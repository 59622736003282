import React from 'react'
import { Link } from 'react-router-dom'

const navigation = [
  { name: 'Terms and Conditions', href: '/terms' },
  { name: 'Privacy Policy', href: '/privacy' },
  // { name: 'FAQ', href: '/faq' },
  { name: 'Contact Us', href: '/contact' },
]

export default function Footer() {
  return (
    <footer className='py-16 bg-grey800'>
        <div className='flex items-center ml-4 md:ml-12'>
          <Link to='/' className="text-lg text-primary font-bold">
            UZO
          </Link>
          <div className="space-x-8 pl-8">
              {navigation.map((item) => (
                // <div className="first:pl-8">
                  <Link key={item.name} to={item.href} className="text-xs leading-6 text-white font-normal">
                    {item.name}
                  </Link>
                // </div>
              ))}
          </div>
        </div>
        <div className='border-t-2 border-t-grey600 mt-6 pt-6 md:ml-24 px-4 md:px-6 text-grey400 text-xs text-center md:text-left'>
          Copyright © Uzo.  All rights reserved.
        </div>
        
    </footer>
  )
}
