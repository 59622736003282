import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'

export default function Privacy() {
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className='flex flex-col min-h-screen justify-between'>
      <div className='grow flex flex-col'>
        <Header />
        <PDFContent />
      </div>
      <Footer />
    </div>
  )
}

const PDFContent = () => {
  return (
    <iframe className='h-screen' title="Privacy Policy" src="https://docs.google.com/document/d/1klYlXUUS1MBkJa1SqMU-smyLu7rsVyg-djlOfcm3BgU/pub?embedded=true"></iframe>
  );
}