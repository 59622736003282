import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'

export default function Terms() {
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  return (
    <div className='flex flex-col min-h-screen justify-between'>
      <div className='grow flex flex-col'>
        <Header />
        <PDFContent />
      </div>
      <Footer />
    </div>
  )
}

const PDFContent = () => {
  return (
    <iframe className='h-screen' title='Terms and Conditions' src="https://docs.google.com/document/d/1LO06JVQfrCWzyBC0XL_vEe77JDchrt4yibXUDTSi2rw/pub?embedded=true"></iframe>
  );
}
