import React, { useState } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import PricingData from '../assets/data/pricing.json'

export default function Pricing() {
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className='flex flex-col min-h-screen justify-between'>
      <div className='grow flex flex-col'>
        <Header />
        <Content />
        <PricingList />
      </div>
      <Footer />
    </div>
  )
}

const Content = () => {
  return (
    <div className="bg-secondary">
      <div className="relative overflow-clip overflow-hidden">
        <div className="container mx-auto py-12">
          <div className="flex items-center justify-center space-x-8">
            <div className="text-center max-w-screen-md">
              <div className="font-bold text-[40px] leading-tight">
                The ideal package tailored to your needs
              </div>
              <div className="leading-tight mt-4">
                Choose a package that meets your needs. Whether you’re just getting started or looking for complex integration, <b>UZO</b> has what you need.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const PricingList = () => {
  enum Services {
    AppBuilder,
    UssdCode
  };

  const [selectedService, setSelectedService] = useState<Services>(Services.AppBuilder)
  
  const showAppBuilderPackages = () => {
    setSelectedService(Services.AppBuilder);
  }

  const showUssdCodesPackages = () => {
    setSelectedService(Services.UssdCode);
  }

  return (
    <div className='bg-white'>
      <div className="mx-auto py-8">
        <div className='py-8 max-w-6xl mx-auto'>
          <div className='flex flex-row justify-center items-center text-xl mb-4 space-x-8'>
            <div className={`py-2 cursor-pointer ${selectedService === Services.AppBuilder ? 'font-bold text-orange border-b-2 border-orange' : ""}`} onClick={showAppBuilderPackages}>App Builder</div>
            <div className={`py-2 cursor-pointer ${selectedService === Services.UssdCode ? 'font-bold text-orange border-b-2 border-orange' : ""}`} onClick={showUssdCodesPackages}>USSD Code</div>
          </div>
          {selectedService === Services.AppBuilder &&
          <>
            <div className='flex flex-col md:flex-row space-x-1'>
              {PricingData.appBuilder.map(pricingData => (
                <>
                  <div className='grow px-4 py-8 basis-1/3 rounded-md'> 
                    <p className='font-bold text-xl'>{pricingData.title}</p>

                    {/* <p className='mt-4'>{pricingData.description}</p> */}

                    <div className='mt-4'>
                      <div className='flex'>
                        <span className='font-bold mt-2'>GHc</span>
                        <span className='font-bold text-5xl'>{pricingData.price.monthly}</span>
                        <span className='self-end'>/monthly</span>
                      </div>
                    </div>

                    <div className='mt-12'>
                      {/* <p className='font-bold text-sm'>Features</p> */}
                      <div className='mt-2 text-sm'>
                        {pricingData.features.monthly.map(feature => (
                          <p>{feature.title}</p>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div className='bg-gradient-to-r md:bg-gradient-to-b from-secondary via-primary to-secondary md:w-[1px] md:h-auto h-[1px] w-full md:my-4 mx-4 last:w-0 last:h-0'></div>
                </>
              ))}
            </div>

            <div className='text-center mt-8 hidden'>
              <a className="text-md rounded-full bg-primary text-white px-6 py-2 border-1 border-black drop-shadow font-bold" href="https://app.uzoussd.com/app/register">Get Started</a>
            </div>
          </>
          }

            {selectedService === Services.UssdCode &&
            <div className='flex flex-col md:flex-row space-x-1 overflow-x-hidden justify-center'>
              {PricingData.ussdCode.map(ussdCodeData => (
                <>
                  <div className='px-4 py-8 basis-1/4 rounded-md'> 
                    <p className='font-bold text-2xl'>{ussdCodeData.title}</p>

                    {ussdCodeData.prices.monthly.map(priceItem => (
                          <div className='flex mt-2 space-x-4'>
                            <span className='text-xl'>{priceItem.title}:</span>
                            <p className='text-xl'>
                              <span className='font-bold mt-2'>GHc</span>
                              <span className='font-bold'>{priceItem.price}</span>
                              <span className='self-end'>/monthly</span>
                            </p>
                          </div>
                      ))
                    }
                  </div>

                  <div className='bg-gradient-to-r md:bg-gradient-to-b from-secondary via-primary to-secondary md:w-[1px] md:h-auto h-[1px] w-full md:my-4 mx-4 last:w-0 last:h-0'></div>
                </>
              ))}
            </div>
            }
        </div>

        <div className='p-8 max-w-6xl mx-auto bg-secondary rounded-2xl'>
          <div className='md:flex block flex-col md:flex-row items-center md:space-x-4'>
            <div className='md:basis-3/4'>
              <p className='font-bold text-2xl'>For dedicated codes and all other needs</p>
              <p className='mt-4'>
                Don't worry if the packages above don't meet your needs or if your enterprise has unique requirements such as acquiring a dedicated USSD code or privately hosting your own version of UZO. Just reach out to our sales team, and we will create a package tailored to your specific needs.
              </p>
            </div>
            <div className='md:basis-1/4 md:text-right text-left md:mt-0 mt-4'>
              <a className='text-md rounded-full bg-primary text-white px-6 py-2 border-1 border-black drop-shadow font-bold' href={`mailto:clientservices@mobivs.com`}>Contact Sales</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
