import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom'

export default function Header() {
  const [shouldShowBoxShadow, setShouldShowBoxShadow] = useState(false);
  const [shouldMobileMenu, setShouldMobileMenu] = useState(false);

  const location = useLocation();
  const pathname = location.pathname;

  const navigation = [
    { name: 'Home', href: '/', active: pathname === '/' },
    { name: 'Pricing', href: '/pricing', active: pathname === '/pricing' },
    { name: 'FAQ', href: '/faq', active: pathname === '/faq' },
    { name: 'Contact Us', href: '/contact', active: pathname === '/contact' },
  ]

  useEffect(() => {
    const scrollHandler = () => {
      window.scrollY > 10 ? setShouldShowBoxShadow(false) : setShouldShowBoxShadow(true)
    };
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, [shouldShowBoxShadow]);


  return (
    <header className={`inset-x-0 top-0 z-50 sticky bg-white ${!shouldShowBoxShadow && `bg-white shadow-lg`}`}>
        <nav className='flex items-center justify-between p-6 lg:px-8' aria-label='Global'>
          <div className='flex lg:flex-1'>
            <Link to='/' className='-m-1.5 p-1.5'>
              <span className='text-bold text-xl font-bold'>UZO</span>
            </Link>
          </div>
          <div className='flex lg:hidden'>
            <button
              onClick={() => setShouldMobileMenu(!shouldMobileMenu)}
              type='button'
              className='-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700'
            >
              <span className='sr-only'>Open main menu</span>
              <img className='h-6 w-6' aria-hidden='true' alt='menu' src='/images/menu.svg' />
            </button>
          </div>
          <div className='hidden lg:flex lg:gap-x-8 lg:justify-end items-center pr-6 border-r border-lightgrey'>
            {navigation.map((item) => (
              <Link key={item.name} to={item.href} className={`group text-sm leading-6 text-grey font-bold transition duration-300`}>
                {item.name}
                <span className={`block max-w-0 group-hover:max-w-full ${item.active ? 'max-w-full' : ''} transition-all duration-500 h-0.5 bg-primary`}></span>
              </Link>
            ))}
          </div>
          <div className='hidden lg:flex lg:gap-x-8 lg:justify-end items-center ml-6'>
            <Link to='https://app.uzoussd.com/app/login' className='text-md text-primary font-bold'>
              Log in
            </Link>
            <Link to='https://app.uzoussd.com/app/register' className='text-md rounded-full bg-primary text-white px-6 py-2 border-1 border-black drop-shadow font-bold'>
              Get Started
            </Link>
          </div>
        </nav>

        {shouldMobileMenu &&
          <div className='lg:hidden absolute px-4 py-2 bg-white w-full flex flex-col shadow-lg'>
            {navigation.map((item) => (
              <Link key={item.name} to={item.href} className='text-sm leading-6 text-grey font-bold py-4'>
                {item.name}
              </Link>
            ))}
        </div>
        }
    </header>
  )
}
