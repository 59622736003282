import React from 'react';
import { Routes, Route, BrowserRouter } from "react-router-dom"
import Home from "./pages/Home"
import Contact from "./pages/Contact"
import Faq from "./pages/Faq"
import Pricing from "./pages/Pricing"
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={ <Home/> } />
        <Route path="contact" element={ <Contact/> } />
        <Route path="faq" element={ <Faq/> } />
        <Route path="pricing" element={ <Pricing/> } />
        <Route path="privacy" element={ <Privacy/> } />
        <Route path="terms" element={ <Terms/> } />
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
