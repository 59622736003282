import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import FaqItem from '../components/FaqItem'
import QuestionData from '../assets/data/faq.json';

export default function Faq() {
  return (
    <>
      <Header />
      <Section1 />
      <Questions />
      <Footer />
    </>
  )
}

const Section1 = () => {
  return (
    <div className='bg-grey900 pt-4 pb-8'>
      <div className="flex justify-center px-4 max-w-6xl mx-auto">
        <div className='grow flex flex-col-reverse lg:flex-row items-center justify-around'>
          <div className='font-bold text-white text-[40px] grow text-center lg:text-left'>
            Frequent Asked Questions
          </div>
          <div className='grow'>
            <img src='/images/questionmark.png' alt="Questions" className='max-h-64' />
          </div>
        </div>
      </div>
    </div>
  );
}

const Questions = () => {
  const [expandedIndex, setExpandedIndex] = React.useState(-1);
  const toggleFaq = (index: number) => {
    setExpandedIndex(index);
  }

  return (
    <div className='bg-grey900'>
      <div className='bg-white py-16 rounded-none lg:rounded-t-[96px]'>
        <div className='max-w-6xl mx-auto'>
          <div className='flex flex-col justify-center mx-4'>
            {QuestionData.map((question, index) => (<FaqItem {...question} isExpanded={expandedIndex === index} onClick={() => toggleFaq(index)} />))}
          </div>
        </div>
      </div>
    </div>
  );
}