import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'

export default function Contact() {
  return (
    <div className='flex flex-col min-h-screen justify-between'>
      <div className='grow flex flex-col'>
        <Header />
        <ContactInfo />
      </div>
      <Footer />
    </div>
  )
}

const ContactInfo = () => {
  const emailAddress = 'support@uzoussd.com';
  const mobileNo = '055 808 6645';
  const mobileNo2 = '055 865 8366';

  return (
    <div className='relative overflow-hidden grow'>
      <div className='absolute lg:-rotate-6 h-[120%] w-[110%] bg-secondary lg:rounded-tl-[300px] -z-10 lg:-left-10 lg:top-14'></div>
      <div className='container mx-auto'>
        <div className='flex items-center py-16 flex-col-reverse md:flex-row px-4 justify-around md:mt-16'>
          <div className='mt-4'>
            <p className='font-bold text-[40px] mb-20'>We’re eager to hear from you.</p>
            <div className='border-b border-solid border-grey100 pb-8 mb-8 max-w-fit'>
              <p className='text-[22px]'>Send us an email</p>
              <p className='text-primary font-bold text-[30px]'><a href={`mailto:${emailAddress}`}>{emailAddress}</a></p>
            </div>

            <div className='max-w-fit'>
              <p className='text-[22px]'>Call us</p>
              <p className='text-primary font-bold text-[30px]'><a href={`tel:${mobileNo.replaceAll(' ', '')}`}>{mobileNo}</a></p>
              <p className='text-primary font-bold text-[30px]'><a href={`tel:${mobileNo2.replaceAll(' ', '')}`}>{mobileNo2}</a></p>
            </div>
          </div>
          <div className=''>
            <img src='/images/contactus.png' alt='Contact us' className='max-h-96' />
          </div>
        </div>
      </div>
    </div>
  );
}